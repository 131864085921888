<template>
    <app-header/>
  <Suspense>
      <template #default>
  <app-unsubscribes/>
  </template>
      <template #fallback>
        <app-loading />
      </template>
      </Suspense>
  <app-footer/>
</template>

<script>
import AppFooter from '../../components/layouts/AppFooter.vue'
import AppHeader from '../../components/layouts/AppHeader.vue'
import AppUnsubscribes from '../../components/Users/AppUnsubscribes.vue'
import AppLoading from '../../components/Spinners/AppLoading.vue'
import { Suspense } from 'vue'
export default {
  components: { AppUnsubscribes, AppHeader, AppFooter, AppLoading, Suspense  },
   name: 'Unsubscribes'
}
</script>

<style>

</style>